import { useQuery } from "@apollo/client";
import { FeatureToggle } from "@prisma/client";
import gql from "graphql-tag";
import { UserRole } from "../../../../types/global-types";
import { USER_FRAGMENT } from "./fragments";
import { Me } from "./types/Me";
import { addUserToSentryScope } from "../../../../sentryClientIntegration";

export const getMe = (data: Me | undefined) => ({
  me: data?.me,
  isLoggedIn: Boolean(data?.me),
  isAdmin: data?.me?.role === UserRole.ADMIN ?? false,
  isPartner: data?.me?.role === UserRole.PARTNER ?? false,
  isUser: data?.me?.role === UserRole.USER ?? false,
  myClient: data?.me?.client?.nameInternal ?? null,
  features: new Set<FeatureToggle>(
    data?.me?.activeFeatures?.map((activeFeature) => activeFeature.name)
  ),
});

export const ME_QUERY = gql`
  query Me {
    me {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

const useMe = () => {
  const { data, ...props } = useQuery<Me>(ME_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  addUserToSentryScope(data);

  return {
    ...getMe(data),
    ...props,
  };
};

export default useMe;
