import gql from "graphql-tag";

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
    role
    client {
      id
      nameInternal
    }
    activeFeatures {
      name
    }
  }
`;
