import { CircularProgress, CircularProgressProps } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const Base = styled("div")`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type LoadingScreenProps = {
  style?: React.CSSProperties;
  className?: string;
  size?: string | number;
  color?: CircularProgressProps["color"];
};

const LoadingIndicator: React.FC<LoadingScreenProps> = ({
  style,
  className,
  size = 40,
  color,
}) => {
  // prevent appearance of scroll bars (especially vertical) due to changing height / width
  // of `Base` due to rotating box of MUI spinner.
  // Set a min height and width equal to the diagonal of the rotating box of the spinner.
  let defaultStyles: React.CSSProperties = {};
  if (Number.isSafeInteger(size)) {
    const diagonal = (size as number) * Math.sqrt(2);
    defaultStyles = {
      minHeight: `${diagonal}px`,
      minWidth: `${diagonal}px`,
    };
  }
  return (
    <Base style={{ ...defaultStyles, ...style }} className={className}>
      <CircularProgress size={size} color={color} />
    </Base>
  );
};

export default LoadingIndicator;
