import { Box } from "@mui/material";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import useMe from "../modules/shared/auth/hooks/useMe";
import LoadingIndicator from "../modules/shared/common/components/LoadingIndicator";

const Home = () => {
  const { me, loading, isLoggedIn } = useMe();
  const router = useRouter();
  const nameInternal = me?.client?.nameInternal;
  const role = me?.role;

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!isLoggedIn) {
      router.push(`/login`);
      return;
    }

    if (nameInternal) {
      router.push(`/client/${nameInternal}`);
    } else if (role === "ADMIN") {
      router.push("/admin/clients");
    } else if (role === "PARTNER") {
      router.push("/partner");
    }
    // See open GitHub issue https://github.com/vercel/next.js/issues/18127
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, nameInternal, isLoggedIn, loading]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <LoadingIndicator />
    </Box>
  );
};

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ["common"])),
  },
});

export default Home;
